import React from 'react'
import {BaseFilter} from "./Base";
import {Select} from "antd";
import {getConditions} from "../utils";

const ValueConstructor = ({condition, row, store}: any) => {
    switch (condition) {
        case "not_in_list":
        case "all_in_list":
        case "in_list": {
            return <div style={{padding: 20}}>
                <Select mode={"multiple"} style={{width: "100%"}} value={row.value} onChange={(value: any) => {
                    store.setValue(row.key, value)
                }} options={row.options}/>
            </div>
        }
    }

    return (
        <></>
    )
}

const SelectFilter = ({row, store}: any) => {
    const availableConditions = getConditions(row)
    return <>
        <BaseFilter row={row} store={store} availableConditions={availableConditions}
                    ValueConstructor={ValueConstructor}/>
    </>
}

export {SelectFilter}
