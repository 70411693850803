import React from 'react'
import {Space, InputNumber} from "antd"
import {BaseFilter} from "./Base";
import {getConditions} from "../utils";

const prefixMap: { [key: string]: string } = {
    gte: ">=",
    lte: "<=",
    equal: "=",
    not_equal: "!=",
}

const ValueConstructor = ({condition, row, store}: any) => {
    switch (condition) {
        case "gte":
        case "lte":
        case "equal":
        case "not_equal": {
            if ((row.value && typeof row.value === 'object')) {
                store.setValue(row.key, undefined)
            }
            return <div style={{padding: 20}}>
                <InputNumber style={{width: "100%"}}
                             step={0.001}
                             prefix={prefixMap[condition] || ''}
                             onChange={(val: any) => {
                                 store.setValue(row.key, val)
                             }}
                             defaultValue={row.value}/>
            </div>
        }
        case "between": {
            if ((row.value && typeof row.value !== 'object') || !row.value) {
                store.setValue(row.key, {})
            }

            return <div style={{padding: 20}}>
                <Space.Compact style={{width: "100%"}}>
                    <InputNumber style={{width: "100%"}} step={0.001} prefix=">=" placeholder="from"
                                 onChange={(val: any) => {
                                     store.setValue(row.key, {
                                         ...row.value,
                                         from: val
                                     })
                                 }}
                                 defaultValue={row.value.from}/>
                    <InputNumber style={{width: "100%"}} step={0.001} prefix="<=" placeholder="to"
                                 defaultValue={row.value.to}
                                 onChange={(val: any) => {
                                     store.setValue(row.key, {
                                         ...row.value,
                                         to: val
                                     })
                                 }}
                    />
                </Space.Compact>
            </div>
        }
    }

    return (
        <></>
    )
}


const DecimalFilter = ({row, store}: any) => {
    const availableConditions = getConditions(row)
    return <>
        <BaseFilter store={store} row={row} availableConditions={availableConditions}
                    ValueConstructor={ValueConstructor}/>
    </>
}

export {DecimalFilter}
