import React from 'react'
import {Flex, Button, Card, Drawer, Input, Badge} from "antd";
import {
    SearchOutlined,
    SettingOutlined,
    FilterOutlined
} from "@ant-design/icons";
import i18n from "i18next";
import {Request} from "kaiju.core";
import {useOutletContext, useParams, useNavigate, generatePath} from "react-router-dom";
import {Table} from "src/components/Table";
import {ROUTE} from "src/routing";
import {usePlugin} from "src/Plugins"
import {Storage} from "src/utils/Storage";
import {ConditionFilters} from 'src/components/ConditionFilters'
import {ColumnContent, useColumns} from 'src/components/Columns'
import {isPermitted, Permissions, Permission403} from 'src/components/Permissions'
import {FiltersComponent} from './filters'
import {HeaderComponent} from "src/components/Header";
import {Actions} from "./Actions";
import {Cells} from './Cells'
import {BulkActions} from "./BulkActions"
import {CreateItem} from '../CreateItem'


interface Params {
    collectionId: string,
    appId: string,
}

interface SelectedProps {
    allSelected: boolean,
    id: string[],
    count: number
}

const ContentListPageMain: React.FC = () => {
    const params = useParams<keyof Params>() as Params
    const [open, setOpen] = React.useState<boolean>(false)
    const [openColumns, setOpenColumns] = React.useState<boolean>(false)
    const [openFilters, setOpenFilters] = React.useState<boolean>(false)
    const [selectedState, setSelectedState] = React.useState<SelectedProps>({
        allSelected: false,
        id: [],
        count: 0,
    })
    const {breadcrumbs, builderStore, contentData} = useOutletContext<{
        breadcrumbs: any,
        builderStore: any,
        contentData: any
    }>()
    const storage = Storage({id: params.collectionId})
    const current = builderStore.data.collection.filter((el: any) => (el._id === (params.collectionId ? params.collectionId : undefined)))[0]
    const navigate = useNavigate()

    const store = Table.useStore(params.collectionId, {
        request: {
            method: "CollectionGui.item.grid",
            params: {
                collection: params.collectionId,
            }
        },
        loadState: () => {
            return storage.get("table") || {}
        },
        onClick: (row: any) => {
            if (isPermitted(params.collectionId, ["update"])) {
                const key = Object.keys(row)
                navigate(generatePath(ROUTE.ContentItem, {...params, itemId: row[key[0]]._id}))
            }
        },
        onSelectedRows: ({allSelected, selectedRows, selectedCount}) => {
            setSelectedState({
                count: selectedCount,
                allSelected: allSelected,
                id: selectedRows.map((row: any) => {
                    const id = Object.keys(row)[0]
                    return row[id]._id
                })
            })
        },
        onColumnOrderChange: (newColumns) => {
            Request("CollectionGui.column.user.set", {
                collection: params.collectionId,
                columns: newColumns
            }).then(() => {

            })
        },
        onStateChange: (state: { [p: string]: any }) => {
            storage.set("table", state)
        }
    })

    const filterStore = ConditionFilters.useFilterStore(params.collectionId, {
        data: storage.get("filters", []),
        onChange: (data) => {
            storage.set("filters", data)
        }
    })

    const columnStore = useColumns({
        id: params.collectionId,
        loadRequest: {
            method: "CollectionGui.column.list",
            params: {collection: params.collectionId}
        },
        loadCurrentRequest: {
            method: "CollectionGui.column.get.current",
            params: {collection: params.collectionId}
        },
        onChange: (newColumns) => {
            Request("CollectionGui.column.user.set", {
                collection: params.collectionId,
                columns: newColumns
            }).then(() => {
                store.fetchData(true)
            })
        }
    })

    const {state} = usePlugin({
        id: params.collectionId,
        state: {
            "collection.grid.extraButtons": React.useMemo(() => [], []),
            "collection.grid.extraFilters": React.useMemo(() => [], []),
        },
        commonData: {
            collectionData: contentData,
            tableStore: store,
            columnStore: columnStore,
            filterStore: filterStore,
        }
    })


    const onCreate = () => {
        store.fetchData()
        setOpen(false)
    }

    const useActions = (row: any, index: number, column: any, id: any) => {
        return <Actions collectionId={params.collectionId}
                        row={row} column={column}
                        id={id}
                        index={index}
                        store={store}/>
    }

    const bulkActionCallback = () => {
        store.fetchData()
        setSelectedState({
            allSelected: false,
            count: 0,
            id: []
        })
    }

    return (
        <>
            <Flex justify={"space-between"} align={"center"} style={{paddingTop: 0, paddingBottom: 20}}>
                <h1 style={{marginBottom: 0, marginTop: 0}}>{current ? current.label : "[...]"}</h1>
                <Flex gap={"small"}>
                    {state["collection.grid.extraButtons"].map((Comp: React.FC) => (Comp))}
                    <Permissions root={params.collectionId} permissions={["create"]}>
                        <Button type={"primary"} onClick={() => {
                            setOpen(true)
                        }}>{i18n.t("item.create")}</Button>
                    </Permissions>
                </Flex>
            </Flex>
            <Flex justify={"space-between"}>
                <div style={{paddingBottom: 20, maxWidth: 500}}>
                    <Input prefix={<SearchOutlined/>} style={{maxWidth: 500}} defaultValue={store.state.q || ''}
                           onChange={(event: any) => {
                               store.querySearch(event.currentTarget.value)
                           }} placeholder={i18n.t("common.search")}/>
                </div>
                <Flex wrap gap="small">
                    {state["collection.grid.extraFilters"].map((Comp: React.FC) => (Comp))}

                    <Badge count={filterStore.data?.length}>
                        <Button onClick={() => {
                            setOpenFilters(true)
                        }} icon={<FilterOutlined/>}/>
                    </Badge>
                    <Button onClick={() => {
                        setOpenColumns(true)
                    }} icon={<SettingOutlined/>}/>
                </Flex>
            </Flex>
            <Card>
                <Table store={store} cells={Cells} actions={useActions}/>
            </Card>
            {params.collectionId &&
                <Permissions root={params.collectionId} permissions={["create"]}>
                    <CreateItem appId={params.appId} collectionId={params.collectionId} collectionData={contentData}
                                open={open} onClose={() => {
                        setOpen(false)
                    }} onCreate={onCreate}/>
                </Permissions>
            }
            <Drawer
                key={"column-" + params.collectionId}
                title={i18n.t("column.select-columns")}
                width={500}
                onClose={() => {
                    setOpenColumns(false)
                }}
                open={openColumns}
                extra={
                    <div>
                        <Button type={"default"} onClick={columnStore.reset}>
                            {i18n.t("column.reset-default")}
                        </Button>
                    </div>
                }
            >
                <ColumnContent key={"column-content-" + columnStore.id} store={columnStore}/>
            </Drawer>
            <FiltersComponent
                key={"filter-" + params.collectionId}
                setFilter={(filters: any[]) => {
                    store.addFilters(filters)
                }}
                params={{collection: params.collectionId,}} filterStore={filterStore} openFilters={openFilters}
                setOpenFilters={setOpenFilters}/>
            <BulkActions collectionId={params.collectionId} appId={params.appId} selectedState={selectedState}
                         successCallback={bulkActionCallback}/>
        </>
    )
}

const ContentListPage: React.FC = () => {
    const params = useParams<keyof Params>() as Params
    const {breadcrumbs} = useOutletContext<{
        breadcrumbs: any
    }>()
    return <>
        <HeaderComponent breadcrumbs={[
            ...breadcrumbs
        ]}/>
        <Permission403 root={params.collectionId} permissions={["read"]}>
            <ContentListPageMain/>
        </Permission403></>

}

export {ContentListPage}
