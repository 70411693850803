import React from 'react'
import DataTable from 'react-data-table-component';
import type {TableColumn} from "react-data-table-component"
import {Pagination, Flex, Divider, Tag, Typography, Avatar, Empty, Skeleton} from 'antd'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import i18n from "i18next";
import type {StoreInterface} from "./store";
import './style.scss'


interface CellMapProps {
    byID?: { [p: string]: (row: any) => void },
    byKind?: { [p: string]: (row: any) => void }

}

interface TableProps {
    store: StoreInterface,
    cells?: CellMapProps,
    title?: string | React.ReactNode
    actions?: (row: any, index: number, column: any, id: any) => React.ReactNode
}

const defaultCell = (row: any, id: string) => {
    switch (typeof row[id]?.value) {
        case "object":
            return <>{row[id].value ?
                <Typography.Paragraph ellipsis={{
                    rows: 2,
                    expandable: true,
                    symbol: i18n.t("common.more")
                }} style={{padding: "5px 0"}}>
                    {JSON.stringify(row[id].value)}
                </Typography.Paragraph>
                : ""}</>
        default:
            return <>{row[id]?.value}</>
    }
}

const defaultByKind: { [p: string]: any } = {
    boolean: (row: any, id: string) => {
        return <>{row[id]?.value ? <CheckOutlined color={"green"}/> : <CloseOutlined/>}</>
    },
    datetime: (row: any, id: string) => {
        return <>{row[id]?.value ? new Date(row[id].value).toLocaleString() : ""}</>
    },
    date: (row: any, id: string) => {
        return <>{row[id]?.value ? new Date(row[id].value).toLocaleDateString() : ""}</>
    },
    media: (row: any, id: string) => {
        return <Avatar.Group
            shape="square"
            size={"large"}
            max={{
                count: 3,
                style: {color: '#f56a00', backgroundColor: '#fde3cf'},
            }}
        >
            {row[id]?.value && row[id]?.value.map((link: string) => {
                return <Avatar key={"img-" + row[id].id + link} size={"large"} src={link}/>
            })}
        </Avatar.Group>
    },
    tag: (row: any, id: string) => {
        return (
            <Typography.Paragraph ellipsis={{
                rows: 2,
                expandable: true,
                symbol: i18n.t("common.more")
            }} style={{padding: "5px 0"}}>
                {Array.isArray(row[id]?.value) && row[id]?.value.map((v: string) => (
                    <Tag>{v}</Tag>
                ))}
            </Typography.Paragraph>
        )
    },
    text: (row: any, id: string) => {
        return (
            <Typography.Paragraph ellipsis={{
                rows: 2,
                expandable: true,
                symbol: i18n.t("common.more")
            }} style={{padding: "5px 0"}}>
                {row[id]?.value}
            </Typography.Paragraph>
        )
    }
}

const Table: React.FC<TableProps> = (props) => {
    const _columns: TableColumn<any>[] = props.store.columns.map((c: any) => {
        let cellConstructor: any = defaultCell
        if (Object.keys(defaultByKind).includes(c.kind)) {
            cellConstructor = defaultByKind[c.kind]
        }

        if (props?.cells?.byKind && props?.cells?.byKind[c.kind]) {
            cellConstructor = props.cells.byKind[c.kind]
        }
        if (props?.cells?.byID && props?.cells?.byID[c.id]) {
            cellConstructor = props.cells.byID[c.id]
        }

        return {
            id: c.id,
            right: c.right,
            compact: c.compact,
            name: c.name ? c.name : c.key ? i18n.t(c.key) : c.id,
            wrap: c.wrap,
            sortable: c.sortable,
            reorder: props.store.reorderColumn,
            cell: (row: any, index: number, column: any, id: any) => {
                return cellConstructor(row, c.id)
            },
        }
    })

    if (props?.actions) {
        _columns.push({
            id: "__actions__",
            compact: undefined,
            right: true,
            ignoreRowClick: true,
            reorder: false,
            sortable: false,
            cell: (row: any, index: number, column: any, id: any) => {
                return <>{props.actions ? props.actions(row, index, column, id) : <></>}</>
            }
        })
    }

    const paginationComponent: React.FC<any> = (pagProps) => {
        return (
            <>
                <Divider style={{marginTop: 0}}/>
                <Flex justify={"center"}>
                    <Pagination
                        total={pagProps.rowCount}
                        pageSizeOptions={pagProps.paginationRowsPerPageOptions}
                        showTotal={(total) => (
                            <>{i18n.t("common.total")}: <Tag color={"geekblue"}><b>{total}</b></Tag></>
                        )}
                        onShowSizeChange={(current: number, size: number) => {
                            pagProps.onChangeRowsPerPage(size, current)
                        }}
                        showLessItems={true}
                        onChange={(page: number) => {
                            pagProps.onChangePage(page, pagProps.rowCount)
                        }}
                        defaultPageSize={props.store.pagination.per_page}
                        defaultCurrent={1}
                        current={props.store.currentPage}
                    />
                </Flex>
            </>
        )
    }

    return <>
        <DataTable
            title={props.title}
            key={`${props.store.id ? props.store.id : 'table'}`}
            pointerOnHover
            highlightOnHover
            keyField={"_id"}
            progressPending={props.store.progressPending}
            paginationPerPage={props.store.pagination.per_page}
            paginationDefaultPage={props.store.currentPage}
            paginationComponent={paginationComponent}
            onRowClicked={props.store.onClick}
            selectableRows={!!props.store.onSelectedRows}
            onSelectedRowsChange={props.store.onSelectedRows}
            selectableRowsHighlight
            onSort={props.store.onSort}
            sortServer
            defaultSortAsc={props.store.state.sort?.direction === 'asc'}
            defaultSortFieldId={"id"}
            progressComponent={
                <Skeleton active loading></Skeleton>
            }
            selectableRowsComponentProps={{
                class: "tbl-select-row"
            }}
            // paginationServerOptions={{
            //     persistSelectedOnPageChange: false,
            //     persistSelectedOnSort: false
            // }}
            responsive={true}
            noDataComponent={<Empty description={i18n.t("common.empty")}/>}
            paginationTotalRows={props.store.pagination.count}
            onChangeRowsPerPage={props.store.onChangeRowsPerPage}
            onChangePage={props.store.onChangePage}
            paginationRowsPerPageOptions={props.store.paginationRowsPerPageOptions}
            pagination
            paginationServer
            onColumnOrderChange={props.store.onColumnOrderChange}
            columns={_columns}
            data={props.store.data}
        />
    </>
}

export {Table}