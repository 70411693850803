import React from 'react'
import {Outlet, useLoaderData, generatePath} from 'react-router-dom';
import {Layout} from 'antd'
import i18n from "i18next";
import {
    ProductOutlined,
    FolderOutlined,
    ToolOutlined,
} from "@ant-design/icons"
import {ROUTE} from "src/routing";
import {Sidebar} from "src/components/Sidebar";
import Plugin from "src/plugin"
import './style.scss'

export const ApplicationRoot: React.FC = () => {
    const applicationData: any = useLoaderData();
    const defaultItems: any[] = React.useMemo(() => [
        {
            key: generatePath(ROUTE.ContentRoot, {appId: applicationData.id}),
            label: i18n.t("collection.label"),
            icon: <ProductOutlined/>,
            permissions: []
        },
        {
            key: generatePath(ROUTE.MediaRoot, {appId: applicationData.id}),
            label: i18n.t("media.label"),
            icon: <FolderOutlined/>,
            permissions: []
        },
        {
            key: generatePath(ROUTE.contentTypeBuilder, {appId: applicationData.id}),
            label: "Content-Type Builder",
            icon: <ToolOutlined/>,
            permissions: ["system", "admin"]
        }
    ], [])
    const [menuItems, setMenuItems] = React.useState<any[]>([])
    React.useLayoutEffect(() => {
        const nestedMenu: any[] = []
        Plugin.invoke("application.menu", nestedMenu, applicationData)
        setMenuItems([...nestedMenu, ...defaultItems])
    }, [applicationData, applicationData.id, defaultItems])
    const breadcrumbs = [
        {
            title: <a href="/">{i18n.t("common.home")}</a>,
        },
        {
            title: <a href={generatePath(ROUTE.application, {appId: applicationData.id})}>{applicationData.meta.code}</a>,
        }
    ]


    return (<>
        <Sidebar key={"app-sidebar" + applicationData.id} data={menuItems}/>
        <Layout>
            <div className={"app-layout"}>
                <Outlet context={{applicationData, breadcrumbs}}/>
            </div>
        </Layout>
    </>)
}
