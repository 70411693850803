import React from 'react'
import {Permissions} from "src/components/Permissions";

const ContentComponent: React.FC<{
    data: any[],
    isActive?: Function,
    onClick: Function,
    onCreate: Function
}> = (props) => {
    return (
        <>
            <ul className="cc-ul">
                {props.data.map((el: any) => (
                    <li
                        key={'cc-li-' + el.id}
                        className={`li-row ${(props.isActive && props.isActive(el)) ? "cc-active" : ""}`}
                        onClick={() => {
                            props.onClick(el)
                        }}
                    > {el.label}</li>
                ))}
            </ul>
        </>
    )
}

export {ContentComponent}