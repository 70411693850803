import React from 'react'
import {DatePicker} from "antd"
import dayjs from 'dayjs';
import {BaseFilter} from "./Base";
import {getConditions} from "../utils";

const prefixMap: { [key: string]: string } = {
    gte: ">=",
    lte: "<=",
    equal: "=",
    not_equal: "!=",
}

const ValueConstructor = ({condition, row, store}: any) => {
    switch (condition) {
        case "gte":
        case "lte":
        case "equal":
        case "not_equal": {
            if ((row.value && typeof row.value === 'object')) {
                store.setValue(row.key, undefined)
            }
            return <div style={{padding: 20}}>
                <DatePicker style={{width: "100%"}}
                            showTime={true}
                            prefix={prefixMap[condition] || ''}
                            onChange={(_: any, val: any) => {
                                store.setValue(row.key, val? dayjs(val).format("YYYY-MM-DDTHH:mm:ss") : val )
                            }}
                            defaultValue={row.value? dayjs(row.value) : row.value}
                />
            </div>
        }
        case "between": {
            if ((row.value && typeof row.value !== 'object') || !row.value) {
                store.setValue(row.key, {})
            }

            const onRangeChange = (dates: any, dateStrings: any) => {
                if (dates) {
                    store.setValue(row.key, {
                        from: dayjs(dateStrings[0]).format("YYYY-MM-DDTHH:mm:ss"),
                        to: dayjs(dateStrings[1]).format("YYYY-MM-DDTHH:mm:ss")
                    })
                } else {
                    store.setValue(row.key, {})
                }
            };
            return <div style={{padding: 20}}>
                <DatePicker.RangePicker
                    defaultValue={row.value? [dayjs(row.value.from), dayjs(row.value.to)]: undefined}
                    showTime={true}
                    style={{width: "100%"}}
                    onChange={onRangeChange}
                />
            </div>
        }
        default:
            return <></>
    }
}


const DatetimeFilter = ({row, store}: any) => {
    const availableConditions = getConditions(row)
    return <>
        <BaseFilter store={store} row={row} availableConditions={availableConditions}
                    ValueConstructor={ValueConstructor}/>
    </>
}

export {DatetimeFilter}
