import React from 'react'

import {Request} from "kaiju.core";

import {SortOrder, TableColumn} from "react-data-table-component/dist/DataTable/types";

interface TableProps {
    request: {
        method: string,
        params?: { [p: string]: any } | undefined
    }
    onSelectedRows?: (selected: {
        allSelected: boolean;
        selectedCount: number;
        selectedRows: any[];
    }) => void,
    onClick?: Function | undefined,
    onColumnOrderChange?: (columns: string[]) => void
    paginationRowsPerPageOptions?: number[],
    preFilters?: any[],
    loadParams?: () => { [p: string]: any }
    loadState?: () => { [p: string]: any }
    onStateChange?: (params: { [p: string]: any }) => void
}

interface PaginationProps {
    page: number,
    per_page: number,
    count: number
}

interface TableStateInterface {
    page: number,
    perPage: number,
    extraParams?: { [p: string]: any },
    q?: string | undefined | null,
    filters?: any[],
    sort?: {
        id: string,
        direction: string
    } | null | undefined
}


interface StoreInterface {
    id: string | undefined,
    data: any[],
    columns: any[],
    onSelectedRows?: (selected: {
        allSelected: boolean;
        selectedCount: number;
        selectedRows: any[];
    }) => void,
    pagination: PaginationProps,
    fetchData: Function,
    onClick: (row: any, event: any) => void,
    querySearch: (q: string | undefined | null) => void,
    addFilters: (filters: any[]) => void,
    onSort: (selectedColumn: TableColumn<any>, sortDirection: SortOrder, sortedRows: any[]) => void,
    onColumnOrderChange?: (nextOrder: TableColumn<any>[]) => void,
    onChangeRowsPerPage: (currentRowsPerPage: number, currentPage: number) => void,
    onChangePage: (page: number) => void,
    clearState: () => void,
    updateExtraParams: (params: { [p: string]: any }) => void,
    state: TableStateInterface,
    progressPending: boolean
    reorderColumn: boolean
    currentPage: number
    paginationRowsPerPageOptions: number[]
}


export const useStore = (id: string | undefined, props: TableProps): StoreInterface => {
    const _state: { [p: string]: any } = props.loadState ? props.loadState() : {};

    const [progressPending, setProgressPending] = React.useState<boolean>(true)

    const [data, setData] = React.useState<any[]>([])
    const [columns, setColumns] = React.useState<any[]>([])

    const [pagination, setPagination] = React.useState<PaginationProps>({
        page: 1,
        per_page: 24,
        count: 0
    })

    const [state, _setState] = React.useState<TableStateInterface>({
        page: _state.page || 1,
        perPage: _state.perPage || 24,
        filters: _state.filters || [],
        q: _state.q,
        sort: _state.sort || null
    })

    const setState = (params: { [p: string]: any }) => {
        _setState({
            ...state,
            ...params
        })
    }

    const clearState = () => {
        const _st: { [p: string]: any } = props.loadState ? props.loadState() : {};
        setState({
            page: _st.page || 1,
            perPage: _st.perPage || 24,
            filters: _st.filters || [],
            q: _st.q,
            sort: _st.sort || null
        })
    }


    const onClick = (row: any, event: any) => {
        if (props.onClick) {
            props.onClick(row, event)
        }
    }

    const fetchData = async (force: boolean = false) => {
        setProgressPending(true)
        await Request(props.request.method, {
            ...props.request.params,
            ...state.extraParams,
            page: state.page,
            per_page: state.perPage,
            filters: [...(props.preFilters ? props.preFilters : []), ...(state.filters || [])],
            q: state.q,
            sort: state.sort
        }).then((resp: any) => {
            if (resp.result) {
                if (resp.result.pagination) {
                    setPagination(resp.result.pagination)
                }

                // if (columns?.length === 0 && resp?.result?.columns?.length > 0 && !force) {
                //     setColumns(resp.result?.columns)
                // } else if (force) {
                setColumns(resp?.result?.columns || [])
                // }

                setData(resp?.result?.data || [])
            } else {
                setData([])
            }
        })
        setProgressPending(false)
    }

    const querySearch = (q: string | null | undefined) => {
        setState({
            q,
            page: 1,
        })
        // fetchData(false)
    }
    const addFilters = (filters: any[]) => {
        setState({
            filters,
            page: 1,
        })
    }

    const onSort = (selectedColumn: any, sortDirection: any) => {
        // call back for sorting
        setState({
            sort: {
                id: selectedColumn.id,
                direction: sortDirection,
            }
        })
    }
    const onColumnOrderChange = (nextOrder: TableColumn<any>[]) => {
        if (props.onColumnOrderChange) {
            props.onColumnOrderChange(nextOrder.map((row: any) => row.id))
        }
        return nextOrder
    }

    React.useEffect(() => {
        fetchData()
        if (props.onStateChange) {
            props.onStateChange(state)
        }
    }, [state])

    const onChangeRowsPerPage = (currentRowsPerPage: number) => {
        if (state.perPage !== currentRowsPerPage) {
            setState({
                page: 1,
                perPage: currentRowsPerPage
            })
        }
    }
    const onChangePage = async (page: number) => {
        setState({page})
    }

    const updateExtraParams = (params: { [p: string]: any }) => {
        setState({
            extraParams: {
                ...state.extraParams,
                ...params
            }
        })
    }

    return {
        id: id,
        addFilters,
        state,
        clearState,
        updateExtraParams,
        querySearch,
        onSelectedRows: props.onSelectedRows,
        paginationRowsPerPageOptions: props.paginationRowsPerPageOptions || [24, 48, 100],
        onChangePage,
        onClick,
        progressPending,
        onSort,
        data,
        columns,
        pagination,
        onChangeRowsPerPage,
        onColumnOrderChange,
        fetchData,
        reorderColumn: !!props.onColumnOrderChange,
        currentPage: state.page
    }
}

export type {StoreInterface}
