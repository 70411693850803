import React from 'react';
import {ConfigProvider, App} from 'antd';
import {createBrowserRouter, generatePath, RouterProvider, useParams} from 'react-router-dom'
import {routes, loadPlugins, createRouteMap} from "src/routing";
import Plugin from "src/plugin";
import {setMapping} from "src/components/FormCustom/Fields";
import packages from "packages"
import lodash from "lodash";

setMapping()

interface WrapperProps {
    element: any,
    path?: string
}

const ResetStateWrapper = (props: WrapperProps) => {
    const params = useParams<{ [p: string]: any }>()
    return <React.Fragment key={generatePath(props.path || "", params)}>
        {props.element}
    </React.Fragment>
}

const makeRouterPaths = (routes: any[], parentPath?: string) => {
    routes.forEach((el: any) => {
        let path = lodash.trimEnd(parentPath, "/") + "/" + lodash.trimStart(el.path || "", "/");

        if (el.id) {
            const currentPath = path[0] !== "/" ? "/" + path : path;
            el.element = <ResetStateWrapper path={currentPath} element={el.element}/>
        }

        if (el.children && el.children.length > 0) {
            el.children = makeRouterPaths(el.children, path)
        }
        return el
    })

    return routes
}

const makePlugins = () => {
    packages.forEach((plugin: any) => {
        console.log("plugin:", plugin)
        Plugin.register(plugin)
    })
}
makePlugins()
loadPlugins(routes)
createRouteMap(routes)
const newRouter = createBrowserRouter(makeRouterPaths(routes))



const Application = () => {
    React.useLayoutEffect(() => {
        Plugin.invoke("application.init")
    }, [])

    return <ConfigProvider>
        <App>
            <RouterProvider router={newRouter}/>
        </App>
    </ConfigProvider>
};
export default Application;