interface StorageParams {
    id: string | number
}

interface StorageInterface {
    set: (key: string, data: { [p: string]: any }) => void
    get: (key: string, defaultValue?: any) => any
    clear: () => any
}

export const Storage = (params: StorageParams): StorageInterface => {
    const searchParams = new URLSearchParams(window.location.search);

    const setParams = (key: string, strData: string) => {
        searchParams.set(key, strData);
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState({}, "", newUrl);
    }

    const set = (key: string, data: { [p: string]: any }) => {
        const strData = JSON.stringify(data)
        // window.sessionStorage.setItem(params.id + ":key-" + key, strData)
        setParams(key, strData)
    }

    const get = (key: string, defaultValue?: any) => {
        let _data = searchParams.get(key)

        // if (!_data) {
        //     _data = window.sessionStorage.getItem(params.id + ":key-" + key)
        // }

        if (_data) {
            try {
                return JSON.parse(_data)
            } catch (e: any) {
                console.log("Error: " + e)
                return defaultValue
            }
        }
        return defaultValue
    }

    const clear = () => {

    }

    return {
        set,
        get,
        clear,
    }
}

export type {StorageInterface, StorageParams}