import React from "react";
import {Button, Empty, Space, Tooltip} from 'antd';
import i18n from "i18next";
import {CopyOutlined, AppstoreAddOutlined} from '@ant-design/icons';
import {Row, Col, Card} from 'antd';
import {Logo} from 'src/icons'
import './style.scss'
import {generatePath} from "react-router-dom";
import {ROUTE} from "src/routing";


const {Meta} = Card;

const ApplicationsComponent: React.FC<{ data: any[], createCallback: any }> = ({data, createCallback}) => {
    // store.loadData()
    return (
        <>
            <div style={{paddingTop: "2rem", paddingBottom: "2rem"}}>
                <Row gutter={[8, 8]}>
                    {data.map((val: any) => (
                        <Col style={{minWidth: 300}}>
                            <a href={generatePath(ROUTE.applicationDashboard, {appId: val.id})}>
                                <Card
                                    style={{width: "100%"}}
                                >
                                    <Meta
                                        avatar={val.meta.logo ? <img src={val.meta.logo} height={50}/> :
                                            <Logo height={50}/>}
                                        title={val.meta.code}
                                        description={
                                            <div style={{maxWidth: 200, display: "flex"}}><p>ID: {val.id}</p>
                                                <Tooltip title="Copy to clipboard">
                                                    <p><CopyOutlined/></p>
                                                </Tooltip>
                                            </div>}
                                    />
                                </Card>
                            </a>
                        </Col>
                    ))}
                </Row>
            </div>
            <Space direction="vertical" style={{width: '100%'}} align={"center"}>
                {data.length === 0 &&
                    <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{height: 200}}
                        description={
                            <h2 className={"fw-normal"}>
                                {i18n.t("application.empty")}
                            </h2>
                        }
                    />
                }
                <div style={{paddingTop: "1.5rem"}}>
                    <Button size="large" shape="round" icon={<AppstoreAddOutlined/>} type="primary"
                            onClick={createCallback}>
                        {i18n.t("application.create")}
                    </Button>
                </div>
            </Space>


        </>
    );
};

export {ApplicationsComponent};