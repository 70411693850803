import React from "react";
import {DeleteAction} from "./delete";
import type {ActionProps} from "./Types";
import {usePlugin} from "src/Plugins";
import {Permissions} from 'src/components/Permissions'


export const Actions: React.FC<ActionProps> = (props) => {
    const anyKey = Object.keys(props.row)[0]
    const rowId = props.row[anyKey]._id
    const {state} = usePlugin({
        id: rowId,
        state: {
            "collection.grid.table.actions": React.useMemo<React.FC<ActionProps>[]>(() => [], [])
        },
        commonData: {
            props: props
        }
    })

    return <div key={"action-" + rowId}>
        {state["collection.grid.table.actions"].map((Comp: React.FC<ActionProps>) => (<Comp {...props}/>))}

        <Permissions root={props.collectionId} permissions={["delete"]}>
           <DeleteAction {...props}/>
        </Permissions>
    </div>
}