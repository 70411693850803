import React from "react";
import Plugin from "src/plugin";

interface usePluginInterface {
    state: { [p: string]: any }
}

type PluginProps = {
    id: string | undefined,
    state: { [p: string]: any },
    commonData?: { [p: string]: any } | undefined
}

export const usePlugin = ({id, state, commonData}: PluginProps): usePluginInterface => {
    React.useLayoutEffect(() => {
        if (id) {
            Object.keys(state).forEach((key: string) => {
                Plugin.invoke(key, state[key], commonData)
            })
        }
    }, [id])

    return {
        state
    }
}