import React from 'react'
import {App, Button, Space, Drawer} from "antd";
import {Form, useDynamicForm} from "kaiju.core";
import i18n from "i18next";

interface Props {
    appId: string
    prefix?: string
    collectionId: string
    itemId: string
    fields?: string[]
    open: boolean
    onClose: () => void
    onSave: () => void
}

export const EditCollectionItem: React.FC<Props> = (props) => {
    const {notification} = App.useApp()
    const onSave = props.onSave;
    const [store] = useDynamicForm({
        autoLoad: true,
        isNested: false,
        loadRequest: {
            method: "WaymorrCollection.item.attributes",
            params: {
                prefix: props.prefix,
                application: props.appId,
                content: props.collectionId,
                item: props.itemId,
                fields: props.fields,
            }
        },
        loadExtraParams: {
            application: props.appId
        },
        saveRequest: {
            method: "CollectionGui.item.attributes.update",
            params: {
                id: props.itemId,
                collection: props.collectionId
            }
        },
        successCallback: (props) => {
            notification.success({
                duration: 2,
                message: i18n.t("common.updated"),
            });
            onSave()
        }
    })


    const save = () => {
        store.submit()
    }
    return (
        <>
            <Drawer
                title={props.itemId || ""}
                width={720}
                onClose={props.onClose}
                open={props.open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        {props.prefix === 'repair' &&
                            <a href={"/repair_report/" + props.itemId} target="_blank">
                                Скачать отчет по ремонту
                            </a>

                        }
                        <Button onClick={props.onClose}>Отменить</Button>
                        <Button onClick={save} type="primary">
                            Сохранить
                        </Button>
                    </Space>
                }
            >
                <Form className={"collection-attrs"} store={store.formStore}/>
            </Drawer>
        </>
    )
}
