import React from 'react'
import {BaseFilter} from "./Base";
import {SelectAsync} from "kaiju.core";
import {getConditions} from "../utils";

const ValueConstructor = ({condition, row, store}: any) => {
    switch (condition) {
        case "not_in_list":
        case "all_in_list":
        case "in_list": {
            return <div style={{padding: 20}}>
                <SelectAsync isMulti onChange={(value: any) => {
                    store.setValue(row.key, value.map((el: any)=>el.value))
                }} optionHandler={row.options_handler} params={row.params} value={row.value}/>
            </div>
        }
    }

    return (
        <></>
    )
}

const CollectionSelectFilter = ({row, store}: any) => {
    const availableConditions = getConditions(row)
    return <>
        <BaseFilter row={row} store={store} availableConditions={availableConditions} ValueConstructor={ValueConstructor}/>
    </>
}

export {CollectionSelectFilter}
